import { API } from 'aws-amplify';
import axios from 'axios';
import {
  LEARN_SUBMISSION_API_ENDPOINT_NAME,
  USER_API_GATEWAY_ENDPOINT,
  USER_API_GATEWAY_ENDPOINT_NAME,
} from '../aws-exports';
import { returnCourseApiName } from './getcourse';
const ENV = process.env.REACT_APP_ENV || 'dev';

export async function getMyProfile() {
  const response = await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/me', {});
  return response;
}

export async function getNameChangeInfo() {
  const response = await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/name-changes', {});
  return response;
}

export async function updateMyProfile(userData) {
  const response = await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/me', {
    body: userData,
    headers: {},
  });
  return response;
}

export async function updateBookingData(updateBookingData) {
  const response = await API.put(USER_API_GATEWAY_ENDPOINT_NAME, `/api/student/booking`, {
    body: updateBookingData,
    headers: {},
  });
  return response;
}

export const getJWTToken = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/auth-jwt', {
    queryStringParameters: { type: 'student' },
  });
};

export const getOnboardingInfo = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/post-payment-onboarding', {});

export const updateOnboardingInfo = async (payload) => {
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/post-payment-onboarding', {
    body: { ...payload },
  });
};

export const verifyCohortChangeFromUserSide = async (payload) => {
  return await API.post(
    USER_API_GATEWAY_ENDPOINT_NAME,
    '/api/v2/check-subscription/confirm-cohort-change-message',
    {
      body: { ...payload },
    }
  );
};

export const checkSubscription = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/v2/check-subscription');
};

export const checkSubscriptionForCourseType = async (courseType) => {
  const courseName = returnCourseApiName(courseType);
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/api/v2/check-subscription/${courseName}`);
};

export const requestAirmeetAccessForCourseType = async (courseType) => {
  const courseName = returnCourseApiName(courseType);
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/request-airmeet-access', {
    queryStringParameters: { courseType: courseName },
  });
};

export const markCoursesAsInterest = async (courses) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/course-interest', {
    body: {
      courses,
    },
  });

export const getUserCredits = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/user-credit');

// company API Service
export const getAllCompanies = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/companies', {});

export const addNewCompany = async (companyData) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/companies', {
    body: companyData,
  });
};

// job-title API Service
export const getAllJobTitles = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/job-titles', {});

export const addNewJobTitle = async (jobTitleData) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/job-titles', {
    body: jobTitleData,
  });
};

// search university API Service
export const searchUniversities = async (searchText) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/universities', {
    queryStringParameters: { searchText },
  });

export const addNewUniversity = async (universityData) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/universities', {
    body: universityData,
  });
};

// Degree API Service
export const getDegree = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/university-degree', {});

export const addNewDegree = async (degree) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/university-degree', {
    body: degree,
  });
};

// education stream API Service
export const getAllEducationStreams = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/university-stream');
};

export const addNewEducationStream = async (body) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/university-stream', {
    body,
  });
};

// onboarding API
export const getOnboardingData = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/v2/user/onboarding', {});
};

export const postOnboardingData = async (body) => {
  return await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/v2/user/onboarding', {
    body,
  });
};

// performance dashboard
export const fetchUserPerformanceData = async (data) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/performance/profile?goalType=${data?.course}&courseId=${data?.id}`
  );
};

// my performance dropdown
export const fetchUserPerformanceHoverboxData = async (data) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/performance/hoverbox?goalType=${data?.course}&courseId=${data?.id}&moduleId=4`
  );
};

// request callback for user
export const postUserDataForCallback = async (data) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/user/onboarding', {
    body: data,
  });
};

//  OTP SERVICES
export const sendOTPForCallback = async (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/send/otp`, {
    body,
  });
};

export const verifyOTPForCallback = async (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/verify/otp`, {
    body,
  });
};

export const sendWhatsappOtp = async (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/user/whatsapp/send-otp`, {
    body,
  });
};

export const verifyWhatsappOtp = async (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/user/whatsapp/verify-otp`, {
    body,
  });
};

// OTP SERVICES
export const sendOTPAfterSignup = async (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/user/phone/send-otp`, {
    body,
  });
};

export const verifyOTPAfterSignup = async (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, `/api/user/phone/verify-otp`, {
    body,
  });
};

// free user FQA
function getFaqUrl() {
  if (ENV === 'dev') {
    return 'https://strapiv2.almagrow.com/api/faq?populate=faqCategory.faq';
  } else if (ENV === 'prod') {
    return 'https://strapiv2.almagrow.com/api/faq?populate=faqCategory.faq';
  } else if (ENV === 'qa') {
    return 'https://strapi-course-lms-qa.almagrow.com/api/faq?populate=faqCategory.faq';
  }
  return 'https://strapiv2.almagrow.com/api/faq?populate=faqCategory.faq';
}

export const getFreeUserFaq = async () => {
  const url = getFaqUrl();
  return fetch(url).then((res) => res.json());
};

export const getEgligibilityForExtension = async () => {
  return API.get(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/career/user-config/almax-config`);
};

export const applyForABCATExtension = async (apiData) => {
  return API.post(LEARN_SUBMISSION_API_ENDPOINT_NAME, '/api/career/apply-for-extension/v2', {
    body: apiData,
  });
};

export async function authDiscordsUser(payload) {
  const response = await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/discord/register-user', {
    body: payload,
  });
  return response;
}

// cohort leaderboard
export const fetchLeaderboardUserData = async (data) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/cohort-leaderboard?courseType=${data}`
  );
};

// get top three leaderboard
export const getTopThreeLeaderboard = async (data) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/cohort-leaderboard/top-three?courseType=${data}`
  );
};

export const getUserAlmapoints = async () => {
  return await API.get(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/user-points`);
};

// get recipts
export const getReceiptFromAPI = () => {
  return API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/receipt');
};

// error logging
export const postErrorLog = async (body) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/error-reporting/frontend-error', {
    body,
  });
};

export const getSelectedCourses = async () => {
  return API.get(USER_API_GATEWAY_ENDPOINT_NAME, `/api/selected-goals`);
};

export const appendToExploredCourses = async (apiData) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/selected-goals/append', {
    body: apiData,
  });
};

export const updateCurrentCourse = async (apiData) => {
  return API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/selected-goals/update-course-status', {
    body: apiData,
  });
};

export const fetchUserReferredCode = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/user/referrer-referral-code', {});
};

export const getReferralInfo = async (goalType) => {
  return await API.get(
    USER_API_GATEWAY_ENDPOINT_NAME,
    `/api/payment/referral-info?goalType=${goalType}`
  );
};

export const getWoolfToken = async () => {
  return await API.get(LEARN_SUBMISSION_API_ENDPOINT_NAME, `/api/woolf/generate-token`);
};

// talk to admission team
export const getAdmissionTeamData = async (apiData) => {
  return await API.get(
    USER_API_GATEWAY_ENDPOINT_NAME,
    `/api/talk-to-admission-team?courseType=${apiData.courseType}&planType=${apiData.planType}&subPlanType=${apiData.subPlanType}`
  );
};

export const postAdmissionTeamData = async (apiData) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/talk-to-admission-team', {
    body: apiData,
  });
};

// should sow banner
export const getShouldShowBanner = async (courseType) => {
  return await API.get(
    USER_API_GATEWAY_ENDPOINT_NAME,
    `/api/banner/should-show?courseType=${courseType}`
  );
};

// referrer info
export const getReferrerInfo = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/referred/referrer-info');
};

// get coach info for upcoming bacth
export const getUpcomingCoachInfo = async (courseType) => {
  return await API.get(
    LEARN_SUBMISSION_API_ENDPOINT_NAME,
    `/api/web-course/current-coach?courseType=${courseType}`
  );
};
